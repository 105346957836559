import store from '@/store'

export const tableOption = {
  search: true,
  menuWidth: 150,
  menuAlign: 'center',
  editBtn: false,
  delBtn: false,
  topPage: false,
  searchSpan: 6,
  selection: false,
  searchLabelWidth: 120,
  menuFixed: 'normal',
  column: [
    {
      label: '分销商名称',
      prop: 'nickName',
      slot: true
    },

    {
      label: '分销商账号',
      prop: 'accountName',
      slot: true
    },

    {
      label: '企业名称',
      prop: 'companyName',
      search: true,
      slot: true
    },

    {
      label: '审核处理状态',
      prop: 'manualApproveStatus',
      minWidth: 100,
      slot: true,
      search: true,
      type: 'select',
      dicData: store.getters.manualApproveStatus
    },
    {
      label: '审核人员',
      prop: 'approveUserName',
      slot: true
    },
    {
      prop: 'certificateTime',
      searchSpan: 10,
      label: '提交时间'
    },
    {
      prop: 'create_time',
      search: true,
      searchSpan: 10,
      searchLabel: '提交时间',
      searchFormSlot: true,
      hide: true
    }
  ]
}

